import React, { useEffect, useState } from "react";
import { toast } from 'bulma-toast';
import 'animate.css/animate.css';

import { PeerType } from "../lib/peer";
import AuthService from "../services/auth-service";
import {
  getCababilities, setRoomCapabilities, getRoomId,
  consumeAllPeers, getRoomHistory,
  createSendTransport, createRecvTransport,
  tryJoinRoom, 
  } from "../room/roomSystem";

import Participant from "./participant";


export default function Conference(props) {
  const [participants, setParticipants] = useState(new Map());
  const [sendTransport, setSendTransport] = useState(null);
  const [rcvTransport, setRcvTransport] = useState(null);
  const [consume, setConsume] = useState(null);
  const [hololensId, setHololensId] = useState(null);

  const [dataReceived, setDataReceived]= useState(null);

  useEffect(() => {
    props.ws.addEventListener("message", setDataReceived);
    getCababilities();
  }, []);

  useEffect(() => {
    if (dataReceived !== null) receiveMessage(dataReceived);
  }, [dataReceived]);

  useEffect(() => {
    if (consume !== null) handleConsumeResponse(consume);
  }, [consume]);

  useEffect(() => {
    if (sendTransport) {
      console.log("SendTransport created try produce...", sendTransport);
      sendTransport.on("connect", async ({ dtlsParameters }, callback, _errback) => {

        props.ws.addEventListener("message", (message) => {
          const dataJson = JSON.parse(message.data);
          if (dataJson.request === "connect-transport") {
            callback();
          }
        });

        props.ws.send(JSON.stringify({
          request: "connect-transport",
          roomId: getRoomId(),
          peerId: AuthService.getCurrentUser().userId,
          transportId: sendTransport.id,
          dtlsParameters,
        }));
      });
      sendTransport.on("produce", handleTransportProduce);
    }
  }, [sendTransport]);

  useEffect(() =>  {
    if (sendTransport && rcvTransport) {
      consumeAllPeers();
    }
  }, [sendTransport, rcvTransport]);

  useEffect(() => {
    async function produceAudio() {
      await sendTransport.produce({
        track: props.localAudio.getAudioTracks()[0],
				// TODO(sylvain): server should know who's socket is who, never
				// trust the client !
        appData: { type: "peer", name: AuthService.getCurrentUser().userName },
      });
    }
    if (props.localAudio && sendTransport) {
      produceAudio();
    }
  }, [props.localAudio, sendTransport]);

  useEffect(() => {
    async function produceVideo() {
      await sendTransport.produce({
        track: props.localVideo.getVideoTracks()[0],
        // TODO(sylvain): server should know who's socket is who
        // never trust the client !
        appData: { type: "peer", name: AuthService.getCurrentUser().userName },
      });
    }
    if (props.localVideo && sendTransport ) {
      console.log("Creating video producer");
      produceVideo();
    }
  }, [props.localVideo, sendTransport]);

  async function handleTransportProduce(
    { kind, rtpParameters, appData },
    callback,
    errback
  ) {
    console.log(
      "Transport::produce [kind:%s, rtpParameters:%o]",
      kind,
      rtpParameters,
      appData.type
    );

    function handleTransportProduceResponse(message) {
      try {
        const dataJson = JSON.parse(message.data);
        if (dataJson.request === "produce") {
          console.warn("produce");
          callback({ id: dataJson.producerData.id });
        }
      } catch (error) {
        console.error("Failed to handle transport connect", error);
        errback(error);
      }
    }

    props.ws.addEventListener("message", handleTransportProduceResponse);
    console.log("Transport : ", sendTransport.id, "sending/producing ", kind);
    props.ws.send(
      JSON.stringify({
        request: "produce",
        roomId: getRoomId(),
        peerId: AuthService.getCurrentUser().userId,
        transportId: sendTransport.id,
        kind,
        rtpParameters,
        appData,
      })
    );
  }

  async function handleConsumeResponse({ consumerData }) {
    console.log("RCV TRANSPORT", rcvTransport);
    const producerId = consumerData.producerId;
    const consumerId = consumerData.consumerId;
    const kind = consumerData.kind;
    const appData = consumerData.appData;
    console.log("TYPE", appData);
    const rtpParameters = consumerData.rtpParameters;
    console.log("RTP", rtpParameters);
    let media = new MediaStream();

    try {
      console.log(
        "trying to consume [kind:%s, producer:%s, consumer:%s]",
        kind,
        producerId,
        consumerId
      );
      console.log(participants.get(consumerData.peerId));
      if (kind === "video") {
        const videoConsumer = await rcvTransport.consume({
          id: consumerId,
          producerId,
          kind,
          rtpParameters,
        });
        console.log("VIDEO CONSUMER", videoConsumer);
        media.addTrack(videoConsumer.track);
        if (appData.type === "hololens") {
          props.onStreamHololens(media);
          setHololensId(consumerData.peerId);
          addVideoToParticipant(consumerData.peerId, media);
        }
        else if (appData.type === "peer") {
          addVideoToParticipant(consumerData.peerId, media);
        }
        else if (appData.type === "largeDisplay") {
          console.log("LargeDisplayy", media);
          props.onStreamLargeDisplay(media);
        }
      } else if (kind === "audio") {
        console.log("#consume before");
        const audioConsumer = await rcvTransport.consume({
          id: consumerId,
          kind,
          producerId,
          rtpParameters,
        });
        media.addTrack(audioConsumer.track);
        addAudioToParticipant(consumerData.peerId, media);
        console.log("#consume after");
      }
      consumerCreated(consumerId, AuthService.getCurrentUser().userId);
    } catch (error) {
      console.error("failed to consume [kind:%s, error:%o]", kind, error);
      throw error;
    }
  }

  function consumerCreated(consumerId, peerId) {
    props.ws.send(JSON.stringify(
    {
      request: "consumer-created",
      roomId: getRoomId(),
      consumerId,
      peerId
    }
    ));
  }

  function makeSendTransport({transportData}) {
    console.log(transportData);
    const transport = createSendTransport(transportData);
    setSendTransport(transport);
  }

  function makeRecvTransport({transportData}) {
    const transport = createRecvTransport(transportData);
    transport.on("connect", async ({ dtlsParameters }, callback, errback) => {
      function transportConnectResponse(message) {
        const dataJson = JSON.parse(message.data);
        if (dataJson.request === "connect-transport") {
          callback();
        }
      }
      props.ws.addEventListener("message", transportConnectResponse);
      props.ws.send(
        JSON.stringify({
          request: "connect-transport",
          roomId: getRoomId(),
          peerId: AuthService.getCurrentUser().userId,
          transportId: transport.id,
          dtlsParameters,
        })
      );
    });
    setRcvTransport(transport);
  }

  function receiveMessage(message) {
    const data = JSON.parse(message.data);
    switch(data.request) {
      case 'room-capabilities':
        setRoomCapabilities(data).then(() => {
          tryJoinRoom(PeerType.USER);
        });
        break;
      case 'created-transport':
        if (data.type === "send") {
          makeSendTransport(data);
        }
        else{
          makeRecvTransport(data);
        }
        break;
      case 'consume':
        setConsume(data);
        break;
      case 'consume-all':
        data.consumerData.forEach(consumer => {
          handleConsumeResponse({consumerData:consumer});
        })
        break;
      case 'room-joined':
        createTransportSend();
        createTransportRcv();
        data.peers.forEach(peer => {
          console.log(peer);
          addParticipant(peer.id, peer.name, peer.type);
        });
        getRoomHistory();
        break;
      case 'peer-closed':
        deleteParticipant(JSON.parse(data.data).id);
        break;
      case 'peer-muted':
        const res = JSON.parse(data.data);
        muteParticipant(res.peerId, res.muteStatus);
        break;
      case 'camera-off':
        addVideoToParticipant(data.peerId, null);
        break;
      case 'new-participant':
        newcomer(data.peerId, data.name);
				console.log("NEW PARTICIPANT, TYPE:", data.peerType);
        addParticipant(data.peerId, data.name, data.peerType);
        break;
    }
  }

	function addParticipant(peerId, name, peerType) {
		if (!participants.has(peerId)) {
			setParticipants(new Map(participants.set(peerId, {
				name: name,
				type: peerType,
				audioSource: undefined,
				videoSource: undefined,
				isMute: false
			})));
		}
	}

  function deleteParticipant(peerId) {
    const newMap = participants;
    toast({
      message: `${newMap.get(peerId).name} has left the call`,
      type: 'is-info',
      duration: 2000,
      position: 'top-center',
      animate: { in: 'fadeInDown', out: 'fadeOutUp' },
    })
    props.onNotification();
    newMap.delete(peerId);
    if (peerId === hololensId) {
      props.onStreamHololens(null);
    }
    setParticipants(new Map(newMap));
  }

  function muteParticipant(peerId, muteStatus) {
    let user = participants.get(peerId);
		// NOTE(sylvain): We can get a "peer-muted" command before actually
		// receiving the peer list and ids ? Yeah I don't really understand either.
    if (user) {
      user.isMute = muteStatus;
      setParticipants(new Map(participants.set(peerId, user)));
    }
  }

  function addAudioToParticipant(peerId, audio) {
    let user = participants.get(peerId);
    user.audioSource = audio;
    setParticipants(new Map(participants.set(peerId, user)));
  }

  function addVideoToParticipant(peerId, video) {
    let user = participants.get(peerId);
    user.videoSource = video;
    setParticipants(new Map(participants.set(peerId, user)));
  }

  function newcomer(peerId, name) {
    if (!participants.has(peerId)) {
      toast({
        message: `${name} is in the call`,
        type: 'is-info',
        duration: 2000,
        position: 'top-center',
        animate: { in: 'fadeInDown', out: 'fadeOutUp' },
      });
    props.onNotification();
    }
  }

  function createTransportRcv() {
      console.log("createTransport() creating recv transport");
      props.ws.send(
        JSON.stringify({
          request: "create-transport",
          roomId: getRoomId(),
          peerId: AuthService.getCurrentUser().userId,
          type: "recv",
        })
      );
  }

  function createTransportSend() {
      console.log("createTransport() creating send transport");
      props.ws.send(
        JSON.stringify({
          request: "create-transport",
          roomId: getRoomId(),
          peerId: AuthService.getCurrentUser().userId,
          type: "send",
        })
      );
  }

	return (
		<div className="is-flex is-half is-flex-direction-row is-justify-content-center is-align-items-flex-start">
			<Participant
				name="You"
				type={PeerType.USER}
				isMute={props.isMute}
				videoSource={props.localVideo}
				audioSource={props.localAudio}
				isLocalUser={true}
			/>
			{[...participants.keys()].map(k =>
				(<Participant
					key={participants.get(k).name}
					name={participants.get(k).name}
					type={participants.get(k).type}
					isMute={participants.get(k).isMute}
					videoSource={participants.get(k).videoSource}
					audioSource={participants.get(k).audioSource}
					isLocalUser={false}
				/>)
			)}
		</div>
	);
}
