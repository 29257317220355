import { useEffect, useState } from 'react';

import RoomService from '../../services/room-service';

import { MediaTableItem } from './item';
import MediaUploadButton from './upload-button';


// TODO(sylvain): filters to display only some types to reduce visual load and
// facilitate search by user
export default function MediaTable(props) {
	const [medias, setMedias] = useState({
		models: [],
		videos : [],
		images: [],
		pdfs: [],
		dicoms: [],
		others: [],
	});

	function updateMediaList() {
		RoomService.getFiles(props.room_id).then(response => {
			setMedias(RoomService.processGetFilesResponse(response.data));
		});
	}

	useEffect(() => {
		updateMediaList();
	}, []);

	function onUploadNewMedia(data) {
		const newMedias = RoomService.processGetFilesResponse(data);
		const updatedMedias = {};
		for (const key of Object.keys(newMedias)) {
			updatedMedias[key] = [...medias[key], ...newMedias[key]]
		}
		setMedias(updatedMedias);
	}

	async function onProcessDeleteMedia(media_id) {
		RoomService.deleteFile(props.room_id, media_id)
			.then(_response => {
				// NOTE(sylvain): since we can't delete multiple medias at once in this
				// table, either we get a 200 and it's deleted, or we get an error and
				// this block does not execute. Hence why "response" is unused.
				const remaining_medias = {}

				for (const key of Object.keys(medias)) {
					remaining_medias[key] = medias[key].filter(file => file.id !== media_id);
				}

				setMedias(remaining_medias);
			})
			.catch(error => {
				console.error(error);
			});
	}

	return (<>
		<table className="table is-fullwidth is-hoverable">
			<thead>
				<tr>
					<td></td>
					<td>name</td>
					<td>type</td>
					<td>
						<MediaUploadButton room_id={props.room_id} onUploadCallback={onUploadNewMedia} />
					</td>
				</tr>
			</thead>
			<tbody>
				{medias.models.map((file, index) =>
					<MediaTableItem
						key={`0-${index}`}
						room_id={props.room_id}
						file={file}
						type="model"
						onDeleteCallback={onProcessDeleteMedia}
					/>
				)}
				{medias.videos.map((file, index) =>
					<MediaTableItem
						key={`1-${index}`}
						room_id={props.room_id}
						file={file}
						type="video"
						onDeleteCallback={onProcessDeleteMedia}
					/>
				)}
				{medias.images.map((file, index) =>
					<MediaTableItem
						key={`2-${index}`}
						room_id={props.room_id}
						file={file}
						type="image"
						onDeleteCallback={onProcessDeleteMedia}
					/>
				)}
				{medias.pdfs.map((file, index) =>
					<MediaTableItem
						key={`3-${index}`}
						room_id={props.room_id}
						file={file}
						type="pdf"
						onDeleteCallback={onProcessDeleteMedia}
					/>
				)}
				{medias.dicoms.map((file, index) =>
					<MediaTableItem
						key={`4-${index}`}
						room_id={props.room_id}
						file={file}
						type="dicom"
						onDeleteCallback={onProcessDeleteMedia}
					/>
				)}
				{medias.others.map((file, index) =>
					<MediaTableItem
						key={`5-${index}`}
						room_id={props.room_id}
						file={file}
						type="other"
						onDeleteCallback={onProcessDeleteMedia}
					/>
				)}
			</tbody>
		</table>
	</>);
}
