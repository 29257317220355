import { useState, useEffect } from "react";

import NavBar from "../components/navbar";
import RoomItem from "../components/roomItem";
import AuthService from "../services/auth-service";
import RoomService from "../services/room-service";
import { Breadcrumb } from '../components/breadcrumb';


function HomePage(props) {
  const pageName = "Rooms";

  const [rooms, setRooms] = useState([]);
  const [roomName, setRoomName] = useState(`Event ${new Date().toUTCString()}`);
  const userLocalStorage = AuthService.getCurrentUser();
  const [enableSpinner, setEnableSPinner] = useState(true);

  useEffect(() => {
    getRooms();
  }, []);

  async function getRooms() {
    const response = await RoomService.getRooms();
    setRooms(response.data);

    // Disabled spinner
    setEnableSPinner(false);
  }

  function onChangeRoomName(event) {
    setRoomName(event.target.value);
  }

  async function onSubmitCreateRoom(event) {
    event.preventDefault();

    const roomData = {
      name: roomName,
      created_by: AuthService.getCurrentUser().userId
    }

    try {
      await RoomService.createRoom(roomData);
      setRoomName(`Event ${new Date().toUTCString()}`);
      await getRooms();
    } catch (error) {
      if (error.response.status == 400) {
        // {"message":"room validation failed","errors":{"name":"Error, expected `name` to be unique. Value: `Room1`"}}
        alert("\nRooms name must be unique.\nThis name is already used, please choose another one.");

      } else {
        alert(error)
      }
    }
  }

  return (
    <>
      <NavBar></NavBar>
      <Breadcrumb currentPage={pageName} />

      <div className="container ">
        <h1 className="title is-h1">{pageName}</h1>


        <section className="section-slim">
          <form className="box" onSubmit={onSubmitCreateRoom}>
            <div className="field is-grouped">
              <p className="control is-expanded">
                <input
                  className="input"
                  type="text"
                  placeholder="Room Name"
                  name="roomname"
                  value={roomName}
                  onChange={onChangeRoomName}
                  required
                />
              </p>
              <p className="control">
                <input
                  className="button is-primary"
                  type="submit"
                  value="Create Room"
                />
              </p>
            </div>
          </form>
        </section>

        {enableSpinner &&
          <progress className="progress is-small is-primary" max="100">10%</progress>
        }
        <div className="columns is-multiline">
          {rooms.map((room) => (
            <RoomItem
              room={room}
              currentUser={userLocalStorage}
              onDelete={(e) => getRooms()}
              key={room._id.toString()}
            ></RoomItem>
          ))}
        </div>
      </div>
    </>
  );
}

export default HomePage;
