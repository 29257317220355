import React, { useEffect, useRef, useState } from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo, faProcedures } from '@fortawesome/free-solid-svg-icons'
import "react-tabs/style/react-tabs.css";

import AudioNotif from "../audio/Notif_Bubble.mp3";
import Conference from "../components/conference";
import BroadcastOnly from "../components/broadcastOnly";
import RoomJoin from "../components/roomJoin";
import NavBar from "../components/navbar";
import RoomBottomBar from "../components/roomBottomBar";
import Proctoring from "../components/proctoring";
import { getWebSocketInstance, getRoomId, getRoomHistory } from "../room/roomSystem";
import AuthService from "../services/auth-service";
import { Breadcrumb } from "../components/breadcrumb";
import RoomService from "../services/room-service";




const VIDEO_CONSTRAINTS = {
	// NOTE(sylvain): keeping this commented for now, large display should not have this kind
	// of limitation, but every other user do. And since large display is a regular user for
	// now, joining with the web interface, we don't impose constraints for now.
	/*
	width: { ideal: 1280, max: 1920 }, // keep resolution at 2 Mpx max ? Overkill for web users ?
	height: { ideal: 720, max: 1080 }, // keep resolution at 2 Mpx max ? Overkill for web users ?
	*/
	facingMode: { ideal: "user" }
}

// NOTE(sylvain): You should be familiar with :
// https://developer.mozilla.org/en-US/docs/Web/API/Permissions_API
// https://developer.mozilla.org/en-US/docs/Web/API/Media_Streams_API
// https://developer.mozilla.org/en-US/docs/Web/API/MediaDevices/getUserMedia
export default function RoomPage() {
	const pageName = "Room ";

	const roomId = new URL(window.location).searchParams.get("id");


	const [roomName, setRoomName] = useState("");

	const [hasJoined, setHasJoined] = useState(false);
	const [hasJoinedAsBot, setHasJoinedAsBot] = useState(false);
	const [ws, setWebsocket] = useState(null);

	const [tabIndex, setTabIndex] = useState(0);
	const [renderTab, setRenderTab] = useState(false);

	const [isAudioActive, setIsAudioActive] = useState(true);
	const [isVideoActive, setIsVideoActive] = useState(false);

	const [videoDevice, setVideoDevice] = useState(null);
	const [audioDevice, setAudioDevice] = useState(null);

	const [videoStream, setVideoStream] = useState(null);
	const [audioStream, setAudioStream] = useState(null);
	const [hololensStream, setHololensStream] = useState(null);
	const [largeDisplayStream, setLargeDisplayStream] = useState(null);



	const audioRef = useRef();

	useEffect(() => {
		return (() => {
			// NOTE(sylvain): release webcam and microphone no matter how this page left by the user
			if (!hasJoined && audioStream) audioStream.getAudioTracks()[0].stop();
			if (!hasJoined && videoStream) videoStream.getVideoTracks()[0].stop();
		});
	}, []);

	useEffect(() => {
		// NOTE(sylvain): meaning of this ? should we hide once tabIndex is not 1 anymore ?
		if (tabIndex === 1) setRenderTab(true);
	}, [tabIndex]);

	useEffect(() => {
		// Connect the websocket
		async function connectWebSocket() {
			setWebsocket(await getWebSocketInstance());
			getRoomHistory();
		}
		if (hasJoined) connectWebSocket();

		return () => {
			setWebsocket(null);
		}
	}, [hasJoined]);

	useEffect(() => {
		// Connect the websocket the minimal way
		async function connectWebSocket() {
			setWebsocket(await getWebSocketInstance());
		}
		if (hasJoinedAsBot) connectWebSocket();

		return () => {
			setWebsocket(null);
		}
	}, [hasJoinedAsBot]);

	useEffect(() => {
		return () => {
			if (ws) ws.close();
		};
	}, [ws]);

	useEffect(() => {
		return () => {
			if (audioStream) audioStream.getAudioTracks()[0].stop();
		}
	}, [audioStream]);

	useEffect(() => {
		return () => {
			if (videoStream) videoStream.getVideoTracks()[0].stop();
		}
	}, [videoStream]);

	useEffect(() => {
		if (audioStream) {
			audioStream.getAudioTracks()[0].enabled = isAudioActive;
			const isMuted = !isAudioActive;
			if (ws) ws.send(JSON.stringify({
				request: "peer-muted",
				roomId: getRoomId(),
				peerId: AuthService.getCurrentUser().userId,
				status: isMuted
			}));
		} else {
			attachAudioStream(audioDevice);
		}
	}, [isAudioActive]);

	useEffect(() => {
		if (videoStream) {
			videoStream.getVideoTracks()[0].enabled = isVideoActive;
			if (!isVideoActive) {
				videoStream.getVideoTracks()[0].stop();
				setVideoStream(null);
				if (ws) ws.send(JSON.stringify({
					request: "camera-off",
					roomId: getRoomId(),
					peerId: AuthService.getCurrentUser().userId
				}));
			}
		} else {
			attachVideoStream(videoDevice);
		}
	}, [isVideoActive]);

	function onNotificationToast() {
		audioRef.current.src = AudioNotif;
		audioRef.current.play();
	}

	async function attachVideoStream(deviceId) {
		if (!deviceId) return;

		try {
			setVideoDevice(deviceId);
			setVideoStream(await navigator.mediaDevices.getUserMedia({
				video: { deviceId: { exact: deviceId }, ...VIDEO_CONSTRAINTS }
			}));
			setIsVideoActive(true);
		} catch (err) {
			setIsVideoActive(false);
			console.error(err);
			throw (err);
		}
	}

	async function attachAudioStream(deviceId) {
		if (!deviceId) return;

		try {
			setAudioDevice(deviceId);
			setAudioStream(await navigator.mediaDevices.getUserMedia({
				audio: { deviceId: { exact: deviceId } }
			}));
			setIsAudioActive(true);
		} catch (err) {
			setIsAudioActive(false);
			console.error(err);
			throw (err);
		}
	}

	useEffect(() => {
		// Get room name
		async function getRoom(myRoomId) {
			try {
				const res_room = await RoomService.getRoom(myRoomId);

				setRoomName(res_room.data.room.name);

			} catch (error) {
				console.error(error);
				alert("An error occured while trying to retrieve data for this page. If the error occurs again, please contact Lumys' tech support.");
			}
		}
		getRoom(roomId);
	}, [roomId]);




	return (
		<>
			<NavBar></NavBar>
			<Breadcrumb currentPage={pageName} roomName={roomName} />

			<div className="container">

				{!(hasJoined || hasJoinedAsBot) && <RoomJoin
					localAudio={audioStream}
					localVideo={videoStream}
					onJoinCall={() => { setHasJoined(true) }}
					onJoinAsBot={() => { setHasJoinedAsBot(true) }}
					videoWillBeActive={isVideoActive}
					audioWillBeActive={isAudioActive}
					onChangeVideoWillBeActive={(active) => { setIsVideoActive(active) }}
					onChangeAudioWillBeActive={(active) => { setIsAudioActive(active) }}
					onChangeVideoDevice={(videoDeviceId) => attachVideoStream(videoDeviceId)}
					onChangeAudioDevice={(audioDeviceId) => attachAudioStream(audioDeviceId)}
				/>}
				{hasJoinedAsBot && <div>
					{ws && <BroadcastOnly
						ws={ws}
						localAudio={audioStream}
						localVideo={videoStream}
						isMute={!isAudioActive}
					/>}
				</div>}
				{hasJoined && <div>
					<audio ref={audioRef} playsInline />
					<Tabs
						forceRenderTabPanel={true}
						selectedIndex={tabIndex}
						onSelect={(index) => setTabIndex(index)}
					>
						<div className="tabs is-centered">
							<TabList>
								<Tab>
									<span className="icon is-small">
										<FontAwesomeIcon icon={faVideo} />
									</span>
									<span id="conference">Conference</span>
								</Tab>
								<Tab>
									<span className="icon is-small">
										<FontAwesomeIcon icon={faProcedures} />
									</span>
									<span id="proctoring">Proctoring</span>
								</Tab>
							</TabList>
						</div>

						<TabPanel>
							{ws && <Conference
								ws={ws}
								localAudio={audioStream}
								localVideo={videoStream}
								onStreamHololens={(video) => { setHololensStream(video) }}
								onStreamLargeDisplay={(video) => { setLargeDisplayStream(video) }}
								onNotification={onNotificationToast}
								isMute={!isAudioActive}
							/>}
						</TabPanel>

						<TabPanel>
							{ws && <Proctoring
								ws={ws}
								videoHololens={hololensStream}
								videoLargeDisplay={largeDisplayStream}
								isActive={renderTab}
							/>}
						</TabPanel>
					</Tabs>
				</div>}

				{ws && <RoomBottomBar
					ws={ws}
					audioDevice={audioDevice}
					videoDevice={videoDevice}
					isAudioActive={isAudioActive}
					isVideoActive={isVideoActive}
					activateAudio={(isActive) => { setIsAudioActive(isActive) }}
					activateVideo={(isActive) => { setIsVideoActive(isActive) }}
				/>}
			</div>
		</>
	);
}
