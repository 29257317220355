import * as mediasoup from "mediasoup-client";

import AuthService from "../services/auth-service";


let mediasoupDevice = new mediasoup.Device();
let canProduceVideo,
  canProduceAudio = false;

let remotePeers;
let sendTransport, recvTransport;
let dataProducer;
let myRoomId, peerId;
let produce, consume;
let triedGetCapabilities, tryGetHistory;
let mediaStream;

let socket = undefined;
const SOCKET_CONNEXION_MAX_RETRIES = 8;

console.log("running mediasoup client version %s", mediasoup.version);

class WebSocketConnectionError extends Error {
	constructor(message) {
		this.name = "WebSocketConnectionError";
		this.message = message;
	}
}

// NOTE(sylvain): Effectivley just a singleton. The old name connectWebSocket()
// wasn't really conveying that idea clearly enough IMO.
export async function getWebSocketInstance() {
  if (socket !== undefined) {
    return socket;
  }
  socket = new WebSocket(process.env.REACT_APP_WEBSOCKET_URL);
  socket.addEventListener("open", handleSocketOpen);
  socket.addEventListener("message", handleSocketMessage);
  socket.addEventListener("error", handleSocketError);
  socket.addEventListener("close", handleSocketClose);

	function delay(milliseconds) {
		return new Promise(resolve => {
			setTimeout(resolve, milliseconds);
		});
	}

	let retries = 0;
  // NOTE(sylvain): A WebSocket is not usable once it's created, we need to
  // waitfor it to be OPEN. Using it early crashes the frontend.
  // TODO(sylvain): Do better than this ? A kind of websocket object that
  // is actually checking it's state when used ? And wait like that when trying
  // to send the very first message ?
	while (socket.readyState !== WebSocket.OPEN) {
		if (retries >= SOCKET_CONNEXION_MAX_RETRIES) {
			throw new WebSocketConnectionError("Max connection retries exceeded.");
		}
		await delay(100 * retries);
		retries++;
	}

	return socket;
}

function handleAuthResponse(data) {
  let auth = JSON.parse(data.data);
  if (auth.AuthValidation.valid === true) {
    console.log("Authenticate successfully");
    peerId = AuthService.getCurrentUser().userId;
    console.log("MY PEER ID :", peerId);

    if (triedGetCapabilities) {
      getCababilities();
    }
  }
}

export function sendMessage(message) {
  let input = message;
  let date = new Date();
  let username = AuthService.getCurrentUser().userName;
  console.log(date.toUTCString());
  if (myRoomId) {
    socket.send(
      JSON.stringify({
        request: "message",
        roomId: myRoomId,
        peerId,
        username,
        message: input,
      })
    );
  }
}

export function getCababilities() {
  if (!socket) {
    console.log("Socket not connected");
    triedGetCapabilities = true;
    return;
  }

  if (socket.readyState !== 1) {
    console.log("Socket exist but not connected");
    triedGetCapabilities = true;
    return;
  }

  // NOTE(sylvain): The ultimate boss to remove from the codebase
  const url = new URL(window.location);
  myRoomId = url.searchParams.get("id");
  if (myRoomId) {
    socket.send(
      JSON.stringify({
        request: "room-capabilities",
        roomId: myRoomId,
        peerId: myRoomId,
      })
    );
  }
}

export function getWebsocket() {
  return socket;
}

export function getMessagesHistory() {
  if (myRoomId) {
    socket.send(
      JSON.stringify({
        request: "get-messages-history",
        roomId: myRoomId,
      })
    );
  } else {
    tryGetHistory = true;
  }
}

export function getRoomHistory(){
  if (myRoomId) {
    socket.send(
      JSON.stringify({
        request: "get-room-history",
        roomId: myRoomId,
      })
    );
  }
}

export function getSocketState() {
  return socket.readyState;
}

export function sendNormalizedPoint(nmp) {
  let username = AuthService.getCurrentUser().userName;
  socket.send(
    JSON.stringify({
      request: "normalized-point",
      roomId: myRoomId,
      // NOTE(sylvain): really necessary to nest JSON.stringify() like this ?
      data: JSON.stringify({ x: nmp.x, y: nmp.y, username, peerId }),
    })
  );
}

export function getRoomId() {
  return myRoomId;
}

export function leaveRoom() {
  socket.send(
    JSON.stringify({ request: "leave-room", roomId: myRoomId, peerId })
  );
}

export function createTransportSend() {
  if (produce) {
    console.log("createTransport() creating send transport");
    socket.send(
      JSON.stringify({
        request: "create-transport",
        roomId: myRoomId,
        peerId,
        type: "send",
      })
    );
  } 
}

export function createTransportRcv() {
  if (consume) {
    console.log("createTransport() creating recv transport");
    socket.send(
      JSON.stringify({
        request: "create-transport",
        roomId: myRoomId,
        peerId,
        type: "recv",
      })
    );
  }
}

export async function createAudioProducer(audioTrack){
  if (canProduceAudio && audioTrack) {
    console.log("Try produce AUDIO", sendTransport);
    try{
      const producer = await sendTransport.produce({
        track: audioTrack,
        appData: { type: "peer", name: AuthService.getCurrentUser().userName },
      });
      console.log("Produce AUDIO");
      return producer;
    }
    catch(err){
      console.error(err);
    }
  }
}

export async function createVideoProducer(videoTrack){
  if (canProduceVideo && videoTrack) {
    console.log("VIDEO PRODUCER trying", canProduceVideo, videoTrack, sendTransport);
    const producer = await sendTransport.produce({
      track: videoTrack,
      appData: { type: "peer", name: AuthService.getCurrentUser().userName },
    });
    console.log("VIDEO PRODUCER CREATED");
    return producer;
  }
}




export async function connectLargeDisplay(videoTrack) {
  console.log("VideoTrack", videoTrack);
  await sendTransport.produce({
    track: videoTrack,
    appData: { type: "largeDisplay" },
  });
}

function newDataProducer(id) {
  socket.send(
    JSON.stringify({
      request: "consume-data-producer",
      peerId,
      roomId: myRoomId,
      transportId: recvTransport.id,
      dataProducerId: id,
    })
  );
}

export function sendByDataChannel(data) {
  dataProducer.send(data);
}

async function consumeData(data) {
  console.log("===> ", data.id);
  console.log("===> ", data.producerId);
  console.log("===> ", data.sctpStreamParameters);
  const consumer = await recvTransport.consumeData({
    id: data.id,
    dataProducerId: data.producerId,
    sctpStreamParameters: data.sctpStreamParameters,
  });
  consumer.on("message", dataMessageReceived);
}

function dataMessageReceived(message) {
  console.log(message);
}

function handleSocketOpen() {
  console.log(
    "starting media session [roomId:%s, peerId:%s, produce: %s, consume:%s]",
    myRoomId,
    peerId,
    produce,
    consume
  );
  consume = true;
  produce = true;

  // TODO(sylvain): still used / useful ?
  socket.send(JSON.stringify({ request: "authenticate" }));
}

export async function setRoomCapabilities({ roomRtpCapabilities }) {
  mediasoupDevice = new mediasoup.Device();
  console.log("DEVICE", mediasoupDevice);
  console.log("Device cap", roomRtpCapabilities);
  await mediasoupDevice.load({ routerRtpCapabilities: roomRtpCapabilities });
  console.log("DEVICE AFT", mediasoupDevice);
  canProduceVideo = mediasoupDevice.canProduce("video");
  canProduceAudio = mediasoupDevice.canProduce("audio");
  return true;
}

export function tryJoinRoom(peerType) {
  // NOTE(sylvain): should always be called after setRoomCapabilities, never before.
  // NOTE(sylvain): valid values of parameter 'peerType' are "user" and "large-display",
  // should also include "hololens" in the future ;)
  console.log("try to join room :" + myRoomId);

  socket.send(JSON.stringify({ 
    request: "join-room",
    roomId: myRoomId,
    peerId: peerId,
    username: AuthService.getCurrentUser().userName,
    peerType: peerType,
  }));
  if (tryGetHistory) {
    getMessagesHistory();
  }
}

function roomJoined(data) {
  remotePeers = data.peers;
  console.log("Peer in room", remotePeers);
}

export async function handleTransportProduce(
  { kind, rtpParameters, appData },
  callback,
  errback
) {
  console.log(
    "Transport::produce [kind:%s, rtpParameters:%o]",
    kind,
    rtpParameters,
    appData.type
  );

  async function handleTransportProduceResponse(message) {
    try {
      const dataJson = JSON.parse(message.data);
      if (dataJson.request === "produce") {
        console.warn("produce");
        callback({ id: dataJson.producerData.id });
      }
    } catch (error) {
      console.error("Failed to handle transport connect", error);
      errback(error);
    } finally {
      socket.removeEventListener("message", handleTransportProduceResponse);
    }
  }

  socket.addEventListener("message", handleTransportProduceResponse);
  console.log("Transport : ", sendTransport.id, "sending/producing ", kind);
  socket.send(
    JSON.stringify({
      request: "produce",
      roomId: myRoomId,
      peerId,
      transportId: sendTransport.id,
      kind,
      rtpParameters,
      appData,
    })
  );
}

export function createSendTransport(transportData) {
  console.log("createSendTransport() [transportData:%o]", transportData);
  sendTransport = mediasoupDevice.createSendTransport(transportData);
  console.log("SEND TRANSPORT CREATED", sendTransport);
  return sendTransport;
}

export function createRecvTransport(transportData) {
  console.log("createRecvTransport() [transportData:%o]", transportData);
  recvTransport = mediasoupDevice.createRecvTransport(transportData);
  console.log("RCV TRANSPORT CREATED");
  return recvTransport;
}

export function consumeAllPeers(){
  console.log("Consuming all peers...");
  socket.send(JSON.stringify({
    request: "consume-all",
    roomId: myRoomId,
    peerId,
    transportId: recvTransport.id,
    rtpCapabilities: mediasoupDevice.rtpCapabilities
  }));
}

export function getMediaStream() {
  return mediaStream;
}

function peerClosed(data) {
  let peerId = JSON.parse(data.data).id;
  let remoteAudioNode = document.getElementById(`remoteAudio-${peerId}`);
  let remoteVideoNode = document.getElementById(`${peerId}`);
  if (remoteAudioNode) {
    remoteAudioNode.remove();
  }
  if (remoteVideoNode) {
    remoteVideoNode.remove();
  }
}

function handleNewProducerResponse({ producerData }) {
  console.log("handleNewProducerResponse() [producerData:%o]", producerData);
  console.log("PRODUCE TYPE = ", producerData.appData.type);
  if (!consume) {
    return console.log("consume is false so dont consume");
  }
  socket.send(
    JSON.stringify({
      request: "consume",
      roomId: myRoomId,
      consumerPeerId: peerId,
      producerPeerId: producerData.peerId,
      producerId: producerData.id,
      rtpCapabilities: mediasoupDevice.rtpCapabilities,
      transportId: recvTransport.id,
      appData: producerData.appData,
    })
  );
}

async function handleSocketMessage(message) {
  try {
    const data = JSON.parse(message.data);
    switch (data.request) {
      case "user-validation":
        handleAuthResponse(data);
        break;
      case "room-joined":
        roomJoined(data);
        break;
      case "new-producer":
        handleNewProducerResponse(data);
        break;
      case "new-data-producer":
        newDataProducer(data.id);
        break;
      case "data-consumer":
        consumeData(data.data);
        break;
      case "peer-closed":
        peerClosed(data);
        break;
      case "error":
        console.error("received server error [error:%o]", data.error);
        alert(data.error);
        break;
    }
  } catch (error) {
    console.error("failed to handle socket message", error);
  }
}

function handleSocketError(error) {
  console.error("handleSocketError() [error:%o]", error);
}

function handleSocketClose() {
  console.log("handleSocketClose()");
  socket = undefined;
}
