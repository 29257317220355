import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCalendarAlt, faClock, faSignInAlt, faEdit, faLink, faTrash, faClone } from '@fortawesome/free-solid-svg-icons'

import AuthService from '../services/auth-service';
import RoomService from "../services/room-service";
import UserService from "../services/user-service";

import LinkModal from "./modalLink";


export default function RoomItem(props) {
  const [stateModal, setStateModal] = useState(false);
  const refModal = useRef("modal");
  const [startDate, setStartDate] = useState(props.room && new Date(props.room.start_time));
  const [createdBy, setCreatedBy] = useState("?");
  const [participantsCount, setParticipantsCount] = useState([])
  const [editorsCount, setEditorsCount] = useState([])
  const [medias, setMedias] = useState({
    files: []
  });

  /**
   * Return a delete button, regarding the current user right on the room.
   * @param {*} none
   * @returns HTML code with delete button
   */
  function RoomDeleteButton({ }) {
    return (
      AuthService.isOwnRoom(props.room) ?
        <button className="button card-footer-item is-small is-outlined is-danger" onClick={() => onDeleteRoom()} >
          <span className="icon"><FontAwesomeIcon icon={faTrash} /></span>
          <span>Delete</span>
        </button>
        :
        <button className="button card-footer-item is-small is-outlined is-danger" disabled >
          <span className="icon"><FontAwesomeIcon icon={faTrash} /></span>
          <span>Delete</span>
        </button>
    )
  }

  /**
   * Return an edit button, regarding the current user right on the room.
   * @param {*} none
   * @returns HTML code with an edit button 
   * @todo : Implemente the duplicate function
   */
  function RoomDuplicateButton({ }) {
    return (
      AuthService.canEditRoom(props.room) ?
        <Link to={`/room/${props.id}/duplicate/`} disabled className="button card-footer-item" data-tooltip="Duplicate this room">
          <span className="icon"><FontAwesomeIcon icon={faClone} /></span>
        </Link>
        :
        <button className="button card-footer-item" disabled >
          <span className="icon"><FontAwesomeIcon icon={faClone} /></span>
        </button>
    )
  }

  /**
   * Return an edit button, regarding the current user right on the room.
   * @param {*} none
   * @returns HTML code with an edit button 
   */
  function RoomEditButton({ }) {
    return (
      AuthService.canEditRoom(props.room) ?
        <Link to={`/room/${props.room._id}/edit/`} className="button" data-tooltip="Edit this room" >
          <span className="icon"><FontAwesomeIcon icon={faEdit} /></span>
        </Link>
        :
        <button className="button card-footer-item" disabled data-tooltip="You can't edit this room">
          <span className="icon"><FontAwesomeIcon icon={faEdit} /></span>
        </button>
    )
  }

  /**
   * Display the creation date and hour of a room
   * @param {} none
   * @returns HTML code in a Bulma column
   */
  function RoomCreationDate({ }) {
    return (
      <div className="column">
        <p><b>Creation:</b></p>
        <p>
          <span className="icon"><FontAwesomeIcon icon={faCalendarAlt} /></span>
          <time>{startDate.toLocaleDateString()}</time>
        </p>
        <p>
          <span className="icon"><FontAwesomeIcon icon={faClock} /></span>
          <time>{startDate.toLocaleTimeString()}</time>
        </p>
      </div>);
  }

  /**
   * Display all statistic for a room.
   * - Creator's name
   * - participants count
   * - editors count
   * - files count
   * @param {} none
   * @returns HTML code in a Bulma column
   */
  function RoomStats() {
    return (<div className="column ">
      <p><b>Created by :</b> {createdBy}</p>
      <p><b>Guest{participantsCount > 1 ? "s" : ""}:</b> {participantsCount}</p>
      <p><b>Editor{editorsCount > 1 ? "s" : ""}:</b> {editorsCount}</p>
      <p><b>Document{medias.files.length > 1 ? "s" : ""}:</b> {medias.files.length}</p>
    </div>);
  }

  /**
   * Display tips for a room
  * @param {*} null
  * @returns HTML code in a Bulma notification
  */
  function RoomTips() {
    const hasParticipants = participantsCount > 0 || editorsCount > 0;
    const hasDocuments = medias.files.length > 0;

    if (!hasParticipants) {
      return (<div className="notification is-warning is-light">
        Is this a usefull room ? (there is nobody {(hasDocuments) ? '' : 'and no documents '}inside).
      </div>);
    } else {
      return null;
    }
  }

  // Get the rooms properties and attached medias
  useEffect(() => {
    // Get the rooms creator name
    UserService.getUser(props.room.created_by).then((res) => {
      // If I am the creator, change my name for "you"
      if (props.currentUser.userName == res.data.user.name) {
        setCreatedBy("you");
      } else {
        setCreatedBy(res.data.user.name);
      }
    });

    // Get the participants count to the room
    setParticipantsCount(props.room.participants.length);

    // Get the editors count to the room
    setEditorsCount(props.room.editors.length)

    // Get the documents count for the room
    async function getMedias() {
      const id = props.room._id;

      RoomService.getFiles(id).then(response => {
        setMedias({
          files: response.data.files,
        });
      })
    }

    getMedias();
  }, []);

  function toggleModal() {
    setStateModal(!stateModal);
  }

  function onDeleteRoom() {
    RoomService.deleteRoom(props.room._id)
      .then(response => {
        // NOTE(sylvain): 202 because file deletion is actually happening later
        if (response.status == 202) {
          props.onDelete();
        } else {
          alert(response.data);
        }
      })
      .catch(reason => {
        alert(reason);
      });
  }

  return (
    <div className="column">
      <div className="card room">

        <div className="card-header has-text-centered">
          <h2 className="card-header-title">{props.room.name}</h2>
        </div>

        <div className="card-content legende">
          <div className="columns is-mobile">
            <RoomStats />
            <RoomCreationDate />
          </div>
          <RoomTips />
        </div>

        <div className="card-footer">
          <div className="buttons has-addons are-small">
            <Link to={"/room/?id=" + props.room._id} className="button card-footer-item is-primary" >
              <span className="icon"><FontAwesomeIcon icon={faSignInAlt} /></span>
              <span>Join</span>
            </Link>

            <LinkModal id={props.room._id} innerRef={refModal} active={stateModal} switchState={toggleModal} />
            <button className="button card-footer-item" data-tooltip="Get the link to this room" onClick={() => toggleModal()} >
              <span className="icon"><FontAwesomeIcon icon={faLink} /></span>
            </button>

            <RoomEditButton />
            <RoomDuplicateButton />
            <RoomDeleteButton />
          </div>
        </div>
      </div >
    </div >
  );
}
