import React, { useEffect, useState, useRef } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCamera, faDoorOpen, faMicrophone, faMicrophoneSlash,
    faCameraAlt, faEyeSlash } from '@fortawesome/free-solid-svg-icons';

import { PeerType } from "../lib/peer";

import NoCamScreen from "../images/NoCam.png";
import LargeDisplay from "../images/LargeDisplay.png";


export default function Participant(props) {
	const [average, setAverage] = useState();
	const refVideo = useRef();
	const refAudio = useRef();
	const refBox = useRef();

	const divStyle = {
		flexGrow : props.fullsize ? '1.0' : '0.5'
	}

	useEffect(() => {
			refVideo.current.srcObject = props.videoSource;
	}, [props.videoSource]);

	useEffect(() => {
		if (!props.isLocalUser) {
			refAudio.current.srcObject = props.audioSource;
		}

		let interval;
		if (props.audioSource) {
			interval = Audio(props.audioSource);
		}

		return () => {
			if (interval) clearInterval(interval);
		}
	},[props.audioSource]);

	function Audio(audioSource) {
		const audioContext = new (window.AudioContext || window.webkitAudioContext)();
		const analyser = audioContext.createAnalyser();
		const mediaStream = audioSource;
		const mediaSource = audioContext.createMediaStreamSource(mediaStream);
		mediaSource.connect(analyser);
		//analyser.connect(audioContext.destination);
		return setInterval(() => {
			printHz(analyser);
		}, 100);
	}
	
	function printHz(analyser) {
		analyser.fftSize = 2048;
		const bufferLength = analyser.fftSize;
		const dataArray = new Uint8Array(bufferLength);
		analyser.getByteFrequencyData(dataArray);
		let average = 0;
		for (let i = 0; i < bufferLength; i++) {
			average += dataArray[i];
		}
		setAverage(average / bufferLength);
	}

	useEffect(() => {
		refBox.current.style.backgroundColor = average > 3 ? "#c3bfff" : "#fff";
		//refBox.current.style.flexGrow = "0.5";
	}, [average]);

	return(
		<div className="column has-text-centered" ref={refBox} style={divStyle}>
			<video
				ref={refVideo}
				width="1920"
				height="1080"
				muted
				autoPlay
				playsInline
				disablePictureInPicture
				poster={props.type === PeerType.LARGE_DISPLAY ? LargeDisplay : NoCamScreen}
			></video>
			{!props.isLocalUser && <audio ref={refAudio} autoPlay muted={props.isLocalUser} playsInline />}
			{!props.fullsize && <nav className="level box">
				<div className="level-item has-text-centered">
					<div><p className="title">{props.name}</p></div>
				</div>
				<div className="level-right">
				{props.isMute && <span>
					<FontAwesomeIcon icon={faMicrophoneSlash} />
				</span>}
				</div>
			</nav>}
		</div>
	);
}
